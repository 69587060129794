import { jsx as _jsx } from "react/jsx-runtime";
import { InputBlockType, StaticBlockType } from '@/types';
import { BooleanGroup } from './BooleanGroup';
import { DateSelector, DateTime, Time } from './CalendarSelector';
import { CheckboxGroup } from './CheckboxGroup';
import { Dropdown } from './Dropdown';
import { FileUploadMultiple, FileUploadSingle } from './FileUploadField';
import { FreeDecimalField } from './FreeDecimalField';
import { FreeTextField } from './FreeTextField';
import { HeadingText } from './HeadingText';
import { LocationField } from './LocationField';
import { Markdown } from './Markdown';
import { MediaImage } from './Media/Image';
import { Motif } from './Motif';
import { MultiOptionSelect } from './MultiOptionSelect';
import { NumericalRatingScale } from './NumericalRatingScale';
import { PlainText } from './PlainText';
import { Radio } from './Radio';
import { RadioDynamicCachedQuery } from './RadioDynamicCachedQuery';
import { RadioGroup } from './RadioGroup';
import { VerticalScale } from './VerticalScale';
import { VisualAnalogueScale } from './VisualAnalogueScale';
import { WholeNumberField } from './WholeNumberField';
const InputBlockComponents = {
    [InputBlockType.INPUT_BOOLEAN_BOXES]: BooleanGroup,
    [InputBlockType.INPUT_DATETIME_SELECTOR]: DateTime,
    [InputBlockType.INPUT_DATE_SELECTOR]: DateSelector,
    [InputBlockType.INPUT_DECIMAL]: FreeDecimalField,
    [InputBlockType.INPUT_DROPDOWN]: Dropdown,
    [InputBlockType.INPUT_FILE_MULTIPLE]: FileUploadMultiple,
    [InputBlockType.INPUT_FILE_SINGLE]: FileUploadSingle,
    [InputBlockType.INPUT_FLOAT_ANALOGUE_SCALE]: VisualAnalogueScale,
    [InputBlockType.INPUT_INTEGER_HORIZONTAL_NUMERICAL_RATING_SCALE]: NumericalRatingScale,
    [InputBlockType.INPUT_INTEGER_RADIO_GROUP]: RadioGroup,
    [InputBlockType.INPUT_INTEGER_VERTICAL_SCALE]: VerticalScale,
    [InputBlockType.INPUT_LOCATION]: LocationField,
    [InputBlockType.INPUT_MOTIF]: Motif,
    [InputBlockType.INPUT_MULTI_OPTION_SELECT]: MultiOptionSelect,
    [InputBlockType.INPUT_RADIO]: Radio,
    [InputBlockType.INPUT_RADIO_DYNAMIC_CACHED_QUERY]: RadioDynamicCachedQuery,
    [InputBlockType.INPUT_RANGE_RADIO_GROUP]: RadioGroup,
    [InputBlockType.INPUT_STRING_FREE_TEXT]: FreeTextField,
    [InputBlockType.INPUT_STRING_RADIO_GROUP]: RadioGroup,
    [InputBlockType.INPUT_STRING_TEXT_BOXES]: CheckboxGroup,
    [InputBlockType.INPUT_TIME_SELECTOR]: Time,
    [InputBlockType.INPUT_WHOLE_NUMBER_KEYPAD]: WholeNumberField,
};
export const renderInputBlock = (props) => {
    const Component = InputBlockComponents[props.block.type];
    return Component ? _jsx(Component, Object.assign({}, props)) : null;
};
const StaticBlockComponents = {
    [StaticBlockType.STATIC_HEADING]: HeadingText,
    [StaticBlockType.STATIC_MARKDOWN]: Markdown,
    [StaticBlockType.STATIC_MARKDOWN_INSTRUCTION]: Markdown,
    [StaticBlockType.STATIC_MEDIA_IMAGE]: MediaImage,
    [StaticBlockType.STATIC_TEXT]: PlainText,
};
export const renderStaticBlock = (props) => {
    const Component = StaticBlockComponents[props.block.type];
    return Component ? _jsx(Component, Object.assign({}, props)) : null;
};
