import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/layout';
import { ConfigInlineHeader, EventListItem, StateMessage } from '@/components';
import { useAuth, useConfig } from '@/store';
import { APP_CONFIG } from '@/types';
import { ResolveDate } from '@/utils';
const { ConfigBlockType, ConfigComponentType, ScheduleDataFilterType, ScheduleDataFilterValue, ScheduleSortType, } = APP_CONFIG.COMPONENTS;
const resolveDate = new ResolveDate();
export const applyDataFilter = (events, dataFilter) => {
    const filterMatchesEvent = (event, { key, type, value }) => {
        var _a;
        const matchedValue = (_a = event.meta.find((meta) => meta.key === key)) === null || _a === void 0 ? void 0 : _a.value;
        if (!matchedValue)
            return false;
        switch (type) {
            case ScheduleDataFilterValue.MATCH_BOTH_KEY_AND_VALUE:
                return matchedValue === value;
            case ScheduleDataFilterValue.MATCH_KEY_EXISTS:
                return true;
            case ScheduleDataFilterValue.MATCH_KEY_EXISTS_BUT_VALUE_DIFFERENT:
                return matchedValue !== value;
        }
    };
    return events.filter((event) => {
        if (dataFilter.filter_type === ScheduleDataFilterType.AND) {
            return dataFilter.values.every((filter) => filterMatchesEvent(event, filter));
        }
        if (dataFilter.filter_type === ScheduleDataFilterType.OR) {
            return dataFilter.values.some((filter) => filterMatchesEvent(event, filter));
        }
        return true;
    });
};
export const filterEvents = (events, block) => {
    const filter = block.filter;
    // Event types that should be displayed in the schedule
    const eventTypes = filter.event_types.map((type) => type.toUpperCase());
    // If there is a data filter, apply it to the events
    const dataFilter = filter === null || filter === void 0 ? void 0 : filter.data_filter;
    if (dataFilter) {
        events = applyDataFilter(events, dataFilter);
    }
    const scheduleDate = resolveDate.resolveScheduleDate();
    const filteredEvents = events.filter(({ event_date_time, type, window_end }) => {
        const dateNow = new Date();
        const eventType = type.toUpperCase();
        if (eventTypes.includes(eventType)) {
            const eventDate = new Date(event_date_time);
            const minDate = scheduleDate[filter === null || filter === void 0 ? void 0 : filter.after_date];
            const maxDate = scheduleDate[filter === null || filter === void 0 ? void 0 : filter.before_date];
            const isBeforeEnd = dateNow < new Date(window_end);
            const isBetween = eventDate >= minDate && eventDate <= maxDate;
            return isBeforeEnd && isBetween;
        }
        return false;
    });
    return applyLimit(filteredEvents, block);
};
export const sortEvents = (events, block) => {
    const filter = block.filter;
    const now = new Date();
    const { completed, locked, uncompleted } = events.reduce((acc, event) => {
        const windowStartTime = new Date(event.window_start);
        if (event.completed) {
            acc.completed.push(event);
        }
        else if ((event === null || event === void 0 ? void 0 : event.is_dependency_locked) || windowStartTime > now) {
            acc.locked.push(event);
        }
        else {
            acc.uncompleted.push(event);
        }
        return acc;
    }, { completed: [], locked: [], uncompleted: [] });
    // Sort by date, then by name if the dates are the same
    const dateComparator = (dateProperty, a, b, ascending = true) => {
        const dateComparison = new Date(a[dateProperty].toString()).getTime() -
            new Date(b[dateProperty].toString()).getTime();
        if (dateComparison === 0) {
            return ascending ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
        }
        return dateComparison;
    };
    // Sort arrays of UserPhaseEvent by an arbitrary comparator
    function sortUserPhaseEvents(comparator, ...eventArrays) {
        for (const events of eventArrays) {
            events.sort(comparator);
        }
    }
    switch (filter === null || filter === void 0 ? void 0 : filter.sort_direction) {
        case ScheduleSortType.BACKEND_SORT_KEY:
            sortUserPhaseEvents((a, b) => a.sort_key - b.sort_key, uncompleted);
            sortUserPhaseEvents((a, b) => dateComparator('window_start', b, a), locked, completed);
            break;
        case ScheduleSortType.LATEST:
            sortUserPhaseEvents((a, b) => dateComparator('event_date_time', b, a), uncompleted, locked, completed);
            break;
        case ScheduleSortType.OLDEST:
            sortUserPhaseEvents((a, b) => dateComparator('event_date_time', a, b, false), uncompleted, locked, completed);
            break;
    }
    // Return the sorted array of events with uncompleted first, then locked, then completed
    return [...uncompleted, ...locked, ...completed];
};
export const applyLimit = (events, block) => {
    const { filter: { limit = Number.MAX_VALUE } = {} } = block;
    return events.slice(0, limit);
};
export const ConfigSchedule = () => {
    const { getComponentConfig } = useConfig();
    const { state: { currentUserPhase, schedule }, } = useAuth();
    const events = schedule;
    const dashData = getComponentConfig(ConfigComponentType.DASHBOARD, currentUserPhase);
    const scheduleBlocks = dashData === null || dashData === void 0 ? void 0 : dashData.blocks.filter((block) => block.type === ConfigBlockType.SCHEDULE);
    const processedScheduleBlocks = scheduleBlocks.map((block) => {
        const filteredEvents = filterEvents(events, block);
        const filteredAndSortedEvents = sortEvents(filteredEvents, block);
        const finalEvents = applyLimit(filteredAndSortedEvents, block);
        return {
            allCompleteStateMessaging: block.allCompleteStateMessaging,
            allItemsComplete: filteredEvents.every(({ completed }) => completed),
            emptyStateMessaging: block.emptyStateMessaging,
            events: finalEvents,
            hasDataToDisplay: finalEvents.length > 0,
            header: block.header,
        };
    });
    return (_jsx(_Fragment, { children: processedScheduleBlocks.map(({ allCompleteStateMessaging, allItemsComplete, emptyStateMessaging, events, hasDataToDisplay, header, }, index) => {
            if (!hasDataToDisplay && !emptyStateMessaging && !allCompleteStateMessaging) {
                return null;
            }
            if (allItemsComplete) {
                return _jsx(StateMessage, { message: allCompleteStateMessaging });
            }
            if (!hasDataToDisplay || (allItemsComplete && !allCompleteStateMessaging)) {
                return _jsx(StateMessage, { message: emptyStateMessaging });
            }
            return (_jsxs(VStack, Object.assign({ alignItems: "flex-start", w: "100%" }, { children: [_jsx(ConfigInlineHeader, Object.assign({}, header)), events.map((event) => {
                        return _jsx(EventListItem, Object.assign({}, event), event.id);
                    })] }), `schedule-${index}`));
        }) }));
};
