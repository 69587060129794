var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// TODO: This file needs to be refactored
import { Box, Checkbox, Divider, Flex, Heading, Image, Input, Text, useDisclosure, } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ColouredLogoStacked from '@/assets/img/logo-coloured-stacked.svg';
import { Button, CentredWrapper, FormButton, ModalBase } from '@/components';
import { setLanguage } from '@/config';
import { AuthService, ConfigService } from '@/services';
import { closeLoading, setAppConfig, setPrivateChecked, setUserInvitation, showLoading, useAuth, useConfig, useUI, } from '@/store';
import { HTTP_STATUS, ROUTE, StorageKey, UserType } from '@/types';
import { getLocalStorage, getURLParams } from '@/utils';
export const CodeEntry = () => {
    const { t } = useTranslation();
    const [leftValue, setLeftValue] = useState('');
    const [rightValue, setRightValue] = useState('');
    const [langError, setLangError] = useState(t('page:welcome.unsupportedLangBody'));
    const [submitting, setSubmitting] = useState(false);
    const rightInputRef = useRef();
    const uiContext = useUI();
    const auth = useAuth();
    const configContext = useConfig();
    const navigate = useNavigate();
    const langModal = useDisclosure();
    useEffect(() => {
        setLanguage();
        const { invite_code } = getLocalStorage();
        const { invitation_code } = getURLParams();
        const code = invitation_code !== null && invitation_code !== void 0 ? invitation_code : invite_code;
        if (code) {
            handleUpdateCode(code);
            uiContext.dispatch(setPrivateChecked(true));
            if (invitation_code) {
                uiContext.dispatch(showLoading({
                    message: t('page:welcome.checkingInvitationCodeMessage'),
                    opacity: 1,
                }));
                setTimeout(() => {
                    submitCode(invitation_code);
                }, 200);
            }
        }
    }, []);
    const handleUpdateCode = (value) => {
        const code = value.trim().toUpperCase();
        const index = Math.min(6, code.length);
        const left = code.substring(0, index);
        const right = code.substring(index);
        setLeftValue(left);
        if (right)
            setRightValue(right);
    };
    const handleChange = (e) => {
        const value = e.target.value;
        if (e.target.classList.contains('left-input')) {
            handleUpdateCode(value);
            if (value.length >= 6)
                rightInputRef.current.focus();
        }
        else {
            setRightValue(value);
        }
    };
    const setInvitationCodeCookie = (value) => {
        if (uiContext.state.checked) {
            localStorage.setItem(StorageKey.INVITE_CODE, value);
        }
        else {
            localStorage.removeItem(StorageKey.INVITE_CODE);
        }
    };
    const submitCode = (invitationCode) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c;
        try {
            setSubmitting(true);
            const code = (invitationCode !== null && invitationCode !== void 0 ? invitationCode : leftValue + rightValue).trim().toUpperCase();
            const _d = (yield AuthService.checkInvitationCode(code)).data, { meta } = _d, rest = __rest(_d, ["meta"]);
            if (meta.for === UserType.PARTICIPANT) {
                setInvitationCodeCookie(code);
                auth.dispatch(setUserInvitation(Object.assign(Object.assign({}, rest), { code,
                    meta })));
                const appConfig = yield ConfigService.getAppConfig();
                configContext.dispatch(setAppConfig(appConfig));
                if (meta.valid) {
                    const nextRoute = ((_a = meta === null || meta === void 0 ? void 0 : meta.locales) === null || _a === void 0 ? void 0 : _a.length) ? ROUTE.LANGUAGE : ROUTE.REGISTER;
                    navigate(nextRoute);
                }
                else {
                    if (meta === null || meta === void 0 ? void 0 : meta.locale)
                        setLanguage(meta.locale);
                    navigate(ROUTE.LOGIN);
                }
            }
        }
        catch (e) {
            if (e.status === HTTP_STATUS.UNPROCESSABLE_ENTITY) {
                const message = (_c = (_b = e.data) === null || _b === void 0 ? void 0 : _b.errors[0]) === null || _c === void 0 ? void 0 : _c.locale_error;
                if (message)
                    setLangError(message);
                langModal.onOpen();
            }
        }
        finally {
            setSubmitting(false);
            uiContext.dispatch(closeLoading());
        }
    });
    return (_jsxs(_Fragment, { children: [_jsxs(CentredWrapper, { children: [_jsx(Image, { alt: "uMotif", src: ColouredLogoStacked }), _jsx(Divider, { my: "4" }), _jsx(Heading, Object.assign({ size: "lg" }, { children: t('page:welcome.title') })), _jsx(Text, { children: t('page:welcome.subtitle') }), _jsxs(Flex, Object.assign({ alignItems: "center", as: "form", direction: "column", gridGap: "6", mt: "8", onSubmit: (e) => {
                            e.preventDefault();
                            submitCode();
                        } }, { children: [_jsxs(Flex, Object.assign({ alignItems: "center", justifyContent: "space-around" }, { children: [_jsx(Input, { className: "left-input", onChange: handleChange, value: leftValue, variant: "CODE_ENTRY" }), _jsx(Box, Object.assign({ w: "30px" }, { children: "-" })), _jsx(Input, { className: "right-input", maxLength: 6, onChange: handleChange, ref: rightInputRef, value: rightValue, variant: "CODE_ENTRY" })] })), _jsx(Checkbox, Object.assign({ isChecked: uiContext.state.checked, onChange: (e) => {
                                    uiContext.dispatch(setPrivateChecked(e.target.checked));
                                }, size: "lg" }, { children: _jsx(Text, Object.assign({ fontSize: "sm" }, { children: t('page:welcome.remember') })) })), _jsx(FormButton, Object.assign({ disabled: submitting || leftValue.length === 0 || rightValue.length === 0, loading: submitting, size: "md", type: "submit", w: "fit-content" }, { children: t('page:welcome.submitCodeButton') }))] }))] }), _jsx(ModalBase, Object.assign({ footer: _jsx(Button, Object.assign({ onClick: langModal.onClose }, { children: t('page:welcome.closeButton') })), isOpen: langModal.isOpen, title: t('page:welcome.unsupportedLangTitle') }, { children: _jsx(Text, { children: langError }) }))] }));
};
