export * from './cachedQuery';
export * from './query';
export var CachedQueryType;
(function (CachedQueryType) {
    CachedQueryType["CLIENT_59_OVERLAP"] = "client-59::overlap";
    CachedQueryType["LATEST"] = "cached-query::sourced-data::latest";
    CachedQueryType["CUSTOM_MANY"] = "cached-query::custom::many";
    CachedQueryType["CUSTOM_SINGLE"] = "cached-query::custom::single";
})(CachedQueryType || (CachedQueryType = {}));
export var CachedQueryLatestSourcedDataType;
(function (CachedQueryLatestSourcedDataType) {
    CachedQueryLatestSourcedDataType["BOOLEAN_EXISTS"] = "cached-query::boolean::latest-sourced-data::exists";
    CachedQueryLatestSourcedDataType["BOOLEAN_VALUE"] = "cached-query::boolean::latest-sourced-data::value";
    CachedQueryLatestSourcedDataType["CLIENT_59_DATE_OVERLAP"] = "client-59::latest-sourced-data::date-overlap";
    CachedQueryLatestSourcedDataType["INTEGER_VALUE"] = "cached-query::integer::latest-sourced-data::value";
    CachedQueryLatestSourcedDataType["STRING_VALUE"] = "cached-query::string::latest-sourced-data::value";
    CachedQueryLatestSourcedDataType["TIME_ZONE_VALUE"] = "cached-query::time_zone::latest-sourced-data::value";
    CachedQueryLatestSourcedDataType["ZONED_DATE_TIME_VALUE"] = "cached-query::zoned_datetime::latest-sourced-data::value";
})(CachedQueryLatestSourcedDataType || (CachedQueryLatestSourcedDataType = {}));
export var SourcedDataMetaType;
(function (SourcedDataMetaType) {
    SourcedDataMetaType["FULL_MATCH"] = "sourced-data:meta:full-match";
    SourcedDataMetaType["HAS_KEY"] = "sourced-data:meta:has-key";
})(SourcedDataMetaType || (SourcedDataMetaType = {}));
