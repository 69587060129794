export * from './answer-filter';
export * from './answerResolver';
export * from './booleanResolver';
export * from './dateResolver';
export * from './dateTimeFormatterResolver';
export * from './decimalResolver';
export * from './dependency';
export * from './floatResolver';
export * from './integerResolver';
export * from './pageResolver';
export * from './rangeResolver';
export * from './reusableResolver';
export * from './stringListResolver';
export * from './stringResolver';
export * from './timeResolver';
export * from './timezoneResolver';
export * from './zonedDatetimeResolver';
export var ResolverType;
(function (ResolverType) {
    // BOOLEAN = 'boolean::'
    ResolverType["BOOLEAN"] = "raw::boolean::resolver";
    ResolverType["BOOLEAN_ANSWER"] = "answer_value::boolean::resolver";
    ResolverType["BOOLEAN_CRASH"] = "crash::boolean::resolver";
    ResolverType["BOOLEAN_IS_ANSWERED"] = "isAnswered::boolean::resolver";
    ResolverType["BOOLEAN_IS_ANSWERED_V2"] = "isAnswered::boolean::resolver::v2";
    ResolverType["BOOLEAN_REPEAT_KEY_IS_ANSWER_KEY"] = "repeat_key_is_answer_key::boolean::resolver";
    ResolverType["BOOLEAN_REPEAT_KEY_IS_CONTAINED_IN"] = "repeat_key_is_contained_in::boolean::resolver";
    // COMPUTED VALUE = 'computed_value::'
    ResolverType["GET_COMPUTED_VALUE"] = "get::computed_value::resolver";
    // CUSTOM CACHED QUERY = 'custom-cached-query:'
    ResolverType["CCQ_SINGLE_GET_VALUE"] = "custom-cached-query:single:get-value";
    ResolverType["CCQ_MANY_HAS_MATCHING"] = "custom-cached-query::many::has-matching-entry";
    ResolverType["CCQ_MANY_GET_CURRENT_ITERATION_VALUE"] = "custom-cached-query::many::get-current-iteration-value";
    // DATE = 'date::'
    ResolverType["DATE"] = "raw::date::resolver";
    ResolverType["DATE_CURRENT"] = "current::date::resolver";
    ResolverType["DATE_MODIFICATION"] = "modification::date::resolver";
    // DATETIME = 'datetime::'
    ResolverType["DATETIME_ANSWER"] = "answer_value::datetime::resolver";
    // DECIMAL = 'decimal::'
    ResolverType["DECIMAL"] = "raw::decimal::resolver";
    ResolverType["DECIMAL_ARITHMETIC_BASIC"] = "decimal::arithmetic::basic";
    // DEPENDENCY = 'dependency::'
    ResolverType["DEPENDENCY_ANSWER"] = "answer::dependency";
    // FILTER = 'filter::'
    ResolverType["FILTER_ANSWER_KEY_MATCH"] = "filter::answer_key_match";
    // FLOAT = 'float::'
    ResolverType["FLOAT"] = "raw::float::resolver";
    ResolverType["FLOAT_ANSWER"] = "answer_value::float::resolver";
    // INTEGER = 'integer::'
    ResolverType["INTEGER"] = "raw::integer::resolver";
    ResolverType["INTEGER_ABSOLUTE"] = "integer::absolute::resolver";
    ResolverType["INTEGER_ANSWER"] = "answer_value::integer::resolver";
    ResolverType["INTEGER_ANSWER_COUNT"] = "answer_count::integer::resolver";
    ResolverType["INTEGER_ARITHMETIC_BASIC"] = "integer::arithmetic::basic";
    ResolverType["INTEGER_CURRENT_PAGE_REPEAT_INDEX"] = "current_page_repeat_index::integer::resolver";
    ResolverType["INTEGER_CRASH"] = "crash::integer::resolver";
    ResolverType["INTEGER_DAY_DIFFERENCE"] = "integer::day_diff::resolver";
    ResolverType["INTEGER_MAX_FROM_RANGE"] = "integer::max_from_range::resolver";
    ResolverType["INTEGER_MIN_FROM_RANGE"] = "integer::min_from_range::resolver";
    ResolverType["INTEGER_SWITCH"] = "switch::integer::conditionals";
    // PAGE = 'page::'
    ResolverType["PAGE_BUILDER"] = "builder::page::resolver";
    ResolverType["PAGE_CURRENT"] = "current::page::resolver";
    // RANGE = 'range::'
    ResolverType["RANGE"] = "raw::range::resolver";
    ResolverType["RANGE_ANSWER"] = "answer_value::range::resolver";
    // STRING = 'string::'
    ResolverType["STRING"] = "raw::string::resolver";
    ResolverType["STRING_ANSWER"] = "answer_value::string::resolver";
    ResolverType["STRING_ANSWER_LABEL"] = "resolved_answer_label::string::resolver";
    ResolverType["STRING_CONVERSION_FROM_DATE"] = "typeConversion::date_to_string::resolver";
    ResolverType["STRING_CONVERSION_FROM_DECIMAL"] = "type_conversion::decimal_to_string::resolver";
    ResolverType["STRING_CONVERSION_FROM_INTEGER"] = "typeConversion::integer_to_string::resolver";
    ResolverType["STRING_CONVERSION_FROM_TIME"] = "typeConversion::time_to_string::resolver";
    ResolverType["STRING_CONVERSION_FROM_ZONED_DATE_TIME"] = "typeConversion::zoned_datetime_to_string::resolver";
    ResolverType["STRING_CRASH"] = "crash::string::resolver";
    ResolverType["STRING_CURRENT_PAGE_REPEAT_KEY"] = "current_page_repeat_key::string::resolver";
    ResolverType["STRING_INTERPOLATABLE"] = "interpolatable::string::resolver";
    ResolverType["STRING_SEGMENT_ORIGINATOR"] = "segment-originator::string::resolver";
    ResolverType["STRING_SWITCH"] = "switch::string::conditionals";
    ResolverType["STRING_UUID_V4"] = "uuid_v4::string::resolver";
    // LIST STRING = 'list_string::'
    ResolverType["LIST_STRING_ANSWER"] = "answer_value::list_string::resolver";
    ResolverType["LIST_STRING_ANSWERED_ANSWER_KEYS"] = "answered_answer_keys::list_string::resolver";
    // TIME = 'time::'
    ResolverType["TIME"] = "raw::time::resolver";
    // TIMEZONE = 'timezone::'
    ResolverType["TIMEZONE"] = "raw::time_zone::resolver";
    ResolverType["TIMEZONE_ANSWER"] = "answer_value::time_zone::resolver";
    ResolverType["TIMEZONE_CRASH"] = "crash::time_zone::resolver";
    ResolverType["TIMEZONE_DEVICE"] = "device::time_zone::resolver";
    // ZONED DATE TIME = 'zoned_datetime::'
    ResolverType["ZONED_DATETIME"] = "raw::zoned_datetime::resolver";
    ResolverType["ZONED_DATETIME_ANSWER"] = "answer_value::zoned_datetime::resolver";
    ResolverType["ZONED_DATETIME_CRASH"] = "crash::zoned_datetime::resolver";
    ResolverType["ZONED_DATETIME_CURRENT"] = "current::zoned_datetime::resolver";
    ResolverType["ZONED_DATETIME_MODIFICATION"] = "modification::zoned_datetime::resolver";
    ResolverType["ZONED_DATETIME_START_OF_DAY"] = "start_of_day::zoned_datetime::resolver";
    ResolverType["ZONED_DATETIME_SWITCH"] = "switch::zoned_datetime::conditionals";
    // ANSWER VALUE
    ResolverType["ANSWER_VALUE"] = "answer_value::resolver";
    // LOOKUP = 'lookup::'
    ResolverType["LOOKUP"] = "lookup::resolver";
})(ResolverType || (ResolverType = {}));
