var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import './style.css';
import { FileOrigin, FileStatus, } from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { useRef, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { useTranslation } from 'react-i18next';
import uploadImg from '@/assets/img/icons/upload.svg';
import { useResolveValue, useToast } from '@/hooks';
import { AWSService } from '@/services';
import { DataTest, ValuesType } from '@/types';
import { formatFileSize, getInitialFiles, getInputBlockAnswer } from '@/utils';
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);
export const FileUploadMultiple = ({ block: { answer, cancel_label, completed_label, error_format_label, error_processing_label, formats, id, key, max_files = null, max_files_exceeded_error_label, max_size, remove_label, required, uploading_label, }, defaultValue = [], onChange, options, }) => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const fileUploadRef = useRef(null);
    const [fileList, setFileList] = useState([]);
    const [resolver] = useResolveValue();
    const resolved = {
        label: resolver.resolveString(answer.label),
        labelButtonRemoveItem: resolver.resolveString(remove_label),
        labelFileProcessing: resolver.resolveString(uploading_label),
        labelFileProcessingComplete: resolver.resolveString(completed_label),
        labelFileProcessingError: t('common:message.error.default'),
        labelFileTypeNotAllowed: resolver.resolveString(error_format_label),
        labelMaxFileSizeExceeded: resolver.resolveString(error_processing_label),
        labelMaxFilesExceeded: resolver.resolveString(max_files_exceeded_error_label),
        labelTapToCancel: resolver.resolveString(cancel_label),
        required: resolver.resolveBoolean(required),
    };
    const labels = Object.assign(Object.assign({}, resolved), { labelIdle: `<img src='${uploadImg}'/> ${resolved.label}` });
    const navButtons = [
        document.querySelector(`[data-test=${DataTest.QUESTIONNAIRE_CTA}]`),
        document.querySelector(`[data-test=${DataTest.QUESTIONNAIRE_NAV_FORWARD}]`),
        document.querySelector(`[data-test=${DataTest.QUESTIONNAIRE_NAV_BACKWARD}]`),
    ];
    const sidebar = document.querySelector(`[data-test=${DataTest.WAYFINDER_SIDEBAR}]`);
    const disableQuestionnaireNavigation = (disabled = true) => {
        navButtons.forEach((button) => {
            if (button)
                button.disabled = disabled;
        });
        // Disable sidebar navigation if it exists
        if (!sidebar)
            return;
        sidebar.classList.toggle('disabled', disabled);
    };
    const handleChange = () => {
        var _a;
        const currentFileList = (_a = fileUploadRef === null || fileUploadRef === void 0 ? void 0 : fileUploadRef.current) === null || _a === void 0 ? void 0 : _a.getFiles();
        const files = currentFileList.map((file) => ({
            format: file.fileType,
            id: file.serverId,
            name: file.filename,
            size: file.fileSize,
        }));
        const answerValue = Object.assign(Object.assign({}, answer), { label: resolved.label, value: files });
        const newAnswer = getInputBlockAnswer(ValuesType.FILE_LIST, answerValue, key);
        onChange(newAnswer);
    };
    const handleInit = () => {
        const files = getInitialFiles(defaultValue);
        setFileList(files);
    };
    const handleProcessFiles = () => {
        disableQuestionnaireNavigation(false);
    };
    const handleAbortProcessFile = () => {
        var _a;
        const processingStatuses = [FileStatus.PROCESSING, FileStatus.PROCESSING_QUEUED];
        const currentFileList = (_a = fileUploadRef === null || fileUploadRef === void 0 ? void 0 : fileUploadRef.current) === null || _a === void 0 ? void 0 : _a.getFiles();
        const isProcessing = currentFileList.some((file) => processingStatuses.includes(file.status));
        if (!isProcessing)
            disableQuestionnaireNavigation(false);
    };
    const handleProcessFile = (error, file) => {
        if (file.origin === FileOrigin.INPUT && !error) {
            handleChange();
        }
    };
    const handleAddFile = (_error, file) => {
        if (file.origin !== FileOrigin.LIMBO) {
            disableQuestionnaireNavigation(true);
        }
        file.setMetadata('id', file.id);
    };
    const handleRemoveFile = () => {
        setTimeout(() => handleChange(), 200);
    };
    const handleWarning = (error) => {
        switch (error === null || error === void 0 ? void 0 : error.body) {
            case 'Max files':
                showToast({ message: resolved.labelMaxFilesExceeded });
                break;
            default:
                break;
        }
    };
    const handleServerProcess = (_fieldName, file, metadata, load, error, progress, abort) => __awaiter(void 0, void 0, void 0, function* () {
        const fileEl = document.getElementById(`filepond--item-${metadata.id}`);
        const fileAbortButton = fileEl.querySelector('.filepond--action-abort-item-processing');
        const actions = {
            abortFileProcessing: (action) => {
                fileAbortButton.addEventListener('click', action);
            },
        };
        yield AWSService.createS3File({
            awsFileIds: options,
            filepond: { abort, actions, error, file, load, progress },
        });
    });
    return (_jsx(FilePond, Object.assign({ acceptedFileTypes: formats, allowMultiple: true, credits: false, "data-test": id, files: fileList, maxFileSize: formatFileSize(max_size), maxFiles: max_files, maxParallelUploads: 10, name: key, onaddfile: handleAddFile, oninit: handleInit, onprocessfile: handleProcessFile, onprocessfileabort: handleAbortProcessFile, onprocessfiles: handleProcessFiles, onremovefile: handleRemoveFile, onupdatefiles: setFileList, onwarning: handleWarning, ref: fileUploadRef, server: {
            revert: null,
            remove: null,
            process: handleServerProcess,
        } }, labels)));
};
