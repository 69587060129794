export var InputBlockType;
(function (InputBlockType) {
    InputBlockType["INPUT_BOOLEAN_BOXES"] = "input::boolean::boxes";
    InputBlockType["INPUT_DATE_SELECTOR"] = "input::date::selector";
    InputBlockType["INPUT_DATETIME_SELECTOR"] = "input::datetime::selector";
    InputBlockType["INPUT_DROPDOWN"] = "input::dropdown";
    InputBlockType["INPUT_FILE_SINGLE"] = "input::file";
    InputBlockType["INPUT_FILE_MULTIPLE"] = "input::multiple_file_upload";
    InputBlockType["INPUT_DECIMAL"] = "input::decimal";
    InputBlockType["INPUT_FLOAT_ANALOGUE_SCALE"] = "input::float::analogue_scale";
    InputBlockType["INPUT_MULTI_OPTION_SELECT"] = "input::multi-option-select";
    InputBlockType["INPUT_INTEGER_HORIZONTAL_NUMERICAL_RATING_SCALE"] = "input::integer::horizontal_numerical_rating_scale";
    InputBlockType["INPUT_INTEGER_RADIO_GROUP"] = "input::integer::radio_group";
    InputBlockType["INPUT_INTEGER_VERTICAL_SCALE"] = "input::integer::vertical_scale";
    InputBlockType["INPUT_LOCATION"] = "input::location";
    InputBlockType["INPUT_MOTIF"] = "input::motif";
    InputBlockType["INPUT_RADIO"] = "input::radio";
    InputBlockType["INPUT_RADIO_DYNAMIC_CACHED_QUERY"] = "input::dynamic-choices::radio::cached-query";
    InputBlockType["INPUT_RANGE_RADIO_GROUP"] = "input::range::radio_group";
    InputBlockType["INPUT_STRING_FREE_TEXT"] = "input::string::free_text";
    InputBlockType["INPUT_STRING_RADIO_GROUP"] = "input::string::radio_group";
    InputBlockType["INPUT_STRING_TEXT_BOXES"] = "input::string::text_boxes";
    InputBlockType["INPUT_TIME_SELECTOR"] = "input::time::selector";
    InputBlockType["INPUT_WHOLE_NUMBER_KEYPAD"] = "input::whole_number::keypad";
})(InputBlockType || (InputBlockType = {}));
export var InputBlockTypedAnswerType;
(function (InputBlockTypedAnswerType) {
    InputBlockTypedAnswerType["BOOLEAN"] = "boolean::answers";
    InputBlockTypedAnswerType["DECIMAL"] = "decimal::answers";
    InputBlockTypedAnswerType["INTEGER"] = "integer::answers";
    InputBlockTypedAnswerType["STRING"] = "string::answers";
    InputBlockTypedAnswerType["RANGE"] = "range::answers";
})(InputBlockTypedAnswerType || (InputBlockTypedAnswerType = {}));
export var InputModeType;
(function (InputModeType) {
    InputModeType["STRICT"] = "strict";
})(InputModeType || (InputModeType = {}));
