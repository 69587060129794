import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button, InputControl, PageWrapper, TextField } from '@/components';
import { useAuth } from '@/store';
import { DataTest } from '@/types';
export const Profile = () => {
    const { t } = useTranslation();
    const { handleLogout, state: { user }, } = useAuth();
    return (_jsxs(PageWrapper, Object.assign({ title: t('app:components.navigation.profile') }, { children: [user && (_jsx(InputControl, Object.assign({ isDisabled: true, label: t('app:components.participant.email') }, { children: _jsx(TextField, { maxW: "410px", value: user.data.attributes.email }) }))), _jsx(Divider, { bg: "grey.80", h: "1px", my: "12", opacity: "100%" }), _jsx(Button, Object.assign({ "data-test": DataTest.PROFILE_LOGOUT, onClick: handleLogout, variant: "cancel", w: "fit-content" }, { children: t('app:components.common.logout') }))] })));
};
