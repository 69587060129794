var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from '@/components';
import { useResolveValue } from '@/hooks';
import { DataTest } from '@/types';
import { SectionStatus, buildSceneKey, getSectionStatus, mapItemsToSectionsWithIndices, } from '@/utils';
import { Box, Divider, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
const SectionCard = ({ allSections, goToSection, mostForwardSectionKey, queue, section, }) => {
    const [resolver] = useResolveValue();
    const title = resolver.resolveString(section.title);
    const sectionMap = useMemo(() => mapItemsToSectionsWithIndices(queue.itemsArray, allSections), [queue.itemsArray, allSections]);
    const status = useMemo(() => getSectionStatus(sectionMap, section, mostForwardSectionKey), [sectionMap, section, mostForwardSectionKey]);
    // Highlight the section if the user is currently in it
    const { end_index, start_index } = sectionMap[section.page_key.scene_key];
    const highlight = queue.position >= start_index && queue.position <= end_index;
    const isNavigable = status === SectionStatus.ACTIVE || status === SectionStatus.COMPLETED;
    const handleClick = useCallback(() => {
        if (isNavigable) {
            goToSection(section);
        }
    }, [isNavigable, goToSection, section]);
    return (_jsxs(Flex, Object.assign({ maxWidth: "300px", onClick: handleClick, py: "4", sx: { backgroundColor: highlight ? 'darkBrandBlue.5' : 'transparent' } }, { children: [_jsxs(Box, Object.assign({ sx: { width: '100px', margin: 'auto' } }, { children: [status === SectionStatus.ACTIVE && (_jsx(Icon, { color: "green.60", m: "auto", name: "circle", p: 0, strokeWidth: 3 })), status === SectionStatus.COMPLETED && (_jsx(Icon, { color: "green.60", m: "auto", name: "check-circle", p: 0, type: "solid" }))] })), _jsx(Box, Object.assign({ className: `section-card ${status === SectionStatus.ACTIVE ? 'active' : ''}`, "data-test": `${DataTest.WAYFINDER_SECTION}-${buildSceneKey(section.page_key)}`, sx: { width: '100%', opacity: status === SectionStatus.TODO ? '0.6' : '1.0' } }, { children: _jsx(Text, Object.assign({ className: isNavigable ? 'navigable' : '', px: 4, variant: "WAYFINDER_MENU_ITEM" }, { children: title })) }))] })));
};
export const NavigationMenu = (_a) => {
    var { children = '', goToSection, mostForwardSectionKey, queue, sections = [] } = _a, rest = __rest(_a, ["children", "goToSection", "mostForwardSectionKey", "queue", "sections"]);
    const [height, setHeight] = useState(0);
    const [isDesktop] = useMediaQuery('(min-width: 768px)');
    const sectionCards = sections.map((section, index) => (_jsxs(React.Fragment, { children: [_jsx(SectionCard, { allSections: sections, goToSection: goToSection, mostForwardSectionKey: mostForwardSectionKey, queue: queue, section: section }), _jsx(Divider, {})] }, `section-card-${index}`)));
    useEffect(() => {
        const updateHeight = () => {
            var _a, _b;
            const headerHeight = ((_a = document.querySelector('header')) === null || _a === void 0 ? void 0 : _a.offsetHeight) || 0;
            const mainHeight = ((_b = document.querySelector('main')) === null || _b === void 0 ? void 0 : _b.offsetHeight) || 0;
            const windowHeight = window.innerHeight;
            const bigWindow = mainHeight + headerHeight > windowHeight;
            const calculatedHeight = bigWindow ? windowHeight - headerHeight : mainHeight;
            setHeight(calculatedHeight);
        };
        // Initialise
        updateHeight();
        // Debounce
        const handleResize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(updateHeight, 100);
        };
        let resizeTimeout;
        window.addEventListener('resize', handleResize);
        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(resizeTimeout);
        };
    }, []);
    return (isDesktop && (_jsx(Box, Object.assign({}, rest, { borderInlineEnd: "1px solid", borderInlineEndColor: "darkBrandBlue.5", "data-test": DataTest.WAYFINDER_SIDEBAR, pos: "sticky", sx: {
            '&.disabled': {
                opacity: '0.5',
                pointerEvents: 'none',
            },
        }, top: "0", width: "300px" }, { children: _jsx(Box, Object.assign({ borderInlineEnd: "1px solid", borderInlineEndColor: "darkBrandBlue.5", height: `${height}px`, overflowY: "scroll" }, { children: sectionCards })) }))));
};
