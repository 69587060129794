var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Heading, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, CentredWrapper, FormButton } from '@/components';
import { useToast } from '@/hooks';
import { AuthService } from '@/services';
import { useAuth } from '@/store';
export const PasswordForgot = () => {
    const { t } = useTranslation();
    const { showToast } = useToast();
    const navigate = useNavigate();
    const { state: { userInvitation }, } = useAuth();
    const [loading, setLoading] = useState(false);
    const handleBack = () => navigate(-1);
    const handleResetLink = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield AuthService.forgotPassword(userInvitation.code);
            showToast({
                message: t('app:components.password_forgot.password_reset_message'),
                status: 'success',
            });
        }
        finally {
            setLoading(false);
        }
    });
    return (_jsxs(CentredWrapper, { children: [_jsx(Heading, Object.assign({ size: "lg" }, { children: t('app:components.password_forgot.title') })), _jsx(Text, { children: t('app:components.password_forgot.subtitle') }), _jsxs(Grid, Object.assign({ gridGap: "5", gridTemplateColumns: "1fr 1fr", mt: "8" }, { children: [_jsx(Button, Object.assign({ onClick: handleBack, variant: "outline" }, { children: t('app:components.password_forgot.back_page_cta') })), _jsx(FormButton, Object.assign({ loading: loading, onClick: handleResetLink }, { children: t('app:components.password_forgot.reset_link_cta') }))] }))] }));
};
