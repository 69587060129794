import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Flex, Grid } from '@chakra-ui/react';
import { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useRoutes } from 'react-router-dom';
import { Footer, GeneralErrorModal, Header, Loading, LoadingScreen } from '@/components';
import { useToast } from '@/hooks';
import useNetwork from '@/hooks/useNetwork';
import { routes } from '@/routes';
import { GlobalStyle } from '@/styles/GlobalStyles';
import { useAuth } from './store';
export default () => {
    var _a, _b;
    const { t } = useTranslation();
    const { closeToast, showToast } = useToast();
    const { online } = useNetwork();
    const { state: { currentRoute }, } = useAuth();
    useEffect(() => {
        const message = t('app:components.login.connectivity_error');
        if (online) {
            closeToast(message);
        }
        else {
            showToast({ message, permanent: true });
        }
    }, [online]);
    return (_jsxs(_Fragment, { children: [_jsx(GlobalStyle, {}), _jsxs(ErrorBoundary, Object.assign({ FallbackComponent: GeneralErrorModal }, { children: [_jsx(LoadingScreen, {}), _jsxs(Grid, Object.assign({ gridTemplateColumns: "100%", gridTemplateRows: "auto 1fr auto", minH: "100vh" }, { children: [((_a = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.settings) === null || _a === void 0 ? void 0 : _a.header.display) && _jsx(Header, {}), _jsx(Flex, Object.assign({ __css: {
                                    '& > div ': {
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    },
                                }, as: "main", direction: "column", h: "100%" }, { children: _jsx(Suspense, Object.assign({ fallback: _jsx(Loading, {}) }, { children: useRoutes(routes) })) })), ((_b = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.settings) === null || _b === void 0 ? void 0 : _b.footer.display) && _jsx(Footer, {})] }))] }))] }));
};
