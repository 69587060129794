import { useCallback, useRef } from 'react';
export const usePreventDoubleClick = (callbackFn, delay = 300) => {
    const isProcessing = useRef(false);
    const wrappedCallback = useCallback(() => {
        if (isProcessing.current)
            return;
        isProcessing.current = true;
        callbackFn();
        setTimeout(() => {
            isProcessing.current = false;
        }, delay);
    }, [callbackFn, delay]);
    return wrappedCallback;
};
export default usePreventDoubleClick;
