import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { CentredWrapper, StyledLink } from '@/components';
import { useAuth } from '@/store';
import { DataTest, ROUTE } from '@/types';
export const NotFound = () => {
    const { t } = useTranslation();
    const { isLoggedIn } = useAuth();
    return (_jsxs(CentredWrapper, { children: [_jsx(Heading, Object.assign({ as: "h1", fontSize: "3xl" }, { children: t('page:404.title') })), _jsx(Text, Object.assign({ color: "darkBrandBlue.100", fontSize: "xl" }, { children: t('page:404.subtitle') })), _jsx(StyledLink, Object.assign({ "data-test": DataTest.NOT_FOUND_HOME_LINK, style: { width: 'max-content' }, to: isLoggedIn ? ROUTE.DASHBOARD : ROUTE.HOME, variant: "CTA_BLUE" }, { children: t('page:404.buttonText') }))] }));
};
