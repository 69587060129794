import { v4 as uuid_v4 } from 'uuid';
import { ComputedType, InputBlockTypedAnswerType, StorageKey, ValuesType, } from '@/types';
import { langCode } from './languages';
export const REGEX = {
    STUDY_CODE: /^[a-zA-Z0-9]{5}[1-3]?$/,
    TIME: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    UUID_V4: /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
    MARKDOWN_UNDERLINE: /\+\+(.*?)\+\+/gm,
    MARKDOWN_STRIKE_THROUGH: /~{1,2}((?:(?!\n\n)[^~])+)~{1,2}/gm,
};
export const convertToDotNotation = (obj, prefix = '') => {
    let result = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const newKey = prefix ? `${prefix}.${key}` : key;
            const value = obj[key];
            if (typeof value === 'object' && value !== null) {
                Object.assign(result, convertToDotNotation(value, newKey));
            }
            else {
                result[newKey] = String(value);
            }
        }
    }
    return result;
};
export const pick = (obj, keys) => Object.assign({}, ...keys.map((key) => ({ [key]: obj[key] })));
export const mergeArrays = (...arrays) => {
    const merged = [];
    for (const array of arrays) {
        if (array)
            merged.push(...array);
    }
    return merged;
};
export const removeDuplicatesByKey = (arr, key) => [
    ...new Map(arr.map((item) => [item[key], item])).values(),
];
export const deepAccessProperty = (obj, path) => {
    const keys = path.split('.');
    let value = obj;
    for (const key of keys) {
        if (value.hasOwnProperty(key)) {
            value = value[key];
        }
        else {
            return undefined;
        }
    }
    return value;
};
export const getObjListByKey = (arr, key, keyValue) => {
    var _a;
    const obj = {};
    for (const item of arr) {
        const k = item[key];
        const v = ((_a = item[keyValue]) !== null && _a !== void 0 ? _a : item);
        obj[k] = v;
    }
    return obj;
};
export const getArrListByKey = (arr, key) => arr.reduce((acc, curr) => {
    const k = curr[key];
    !acc[k] ? (acc[k] = [curr]) : acc[k].push(curr);
    return acc;
}, {});
export const getMapByKey = (arr, mapKey) => {
    const map = new Map();
    for (const item of arr) {
        const key = item[mapKey];
        map.set(key, item);
    }
    return map;
};
export const getMapListByKey = (arr, mapKey, mapKeyValue) => {
    const map = new Map();
    for (const item of arr) {
        const key = item[mapKey];
        if (!map.has(key))
            map.set(key, []);
        const value = deepAccessProperty(item, mapKeyValue);
        map.get(key).push(value);
    }
    return map;
};
export const hexToRgb = (hex) => {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgb(${r}, ${g}, ${b})`;
};
export const getLocalStorage = () => {
    const storage = Object.values(StorageKey).reduce((acc, curr) => {
        acc[curr] = localStorage.getItem(curr) || '';
        return acc;
    }, {});
    return storage;
};
export const getQueryParams = (params) => {
    return new URLSearchParams(params).toString();
};
export const getURLParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = {};
    urlSearchParams.forEach((value, key) => {
        params[key] = value;
    });
    return params;
};
export const getMobileOS = () => {
    const ua = navigator.userAgent;
    const isAndroid = /android/i.test(ua);
    const isIOS = /iPad|iPhone|iPod/.test(ua);
    return {
        isAndroid,
        isIOS,
        isMobile: isAndroid || isIOS,
    };
};
export const scrollPage = (selector) => {
    const el = document.querySelector(selector);
    if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
    }
    else {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }
};
export const asyncTimeout = (callback, delay = 3000) => new Promise((resolve) => {
    setTimeout(() => {
        callback();
        resolve(true);
    }, delay);
});
// QUESTIONNAIRE UTILS
export const parseTime = (time = '00:00') => {
    const [hours, minutes] = time.split(':').map(Number);
    return {
        hours,
        minutes,
    };
};
export const formatTimeUnit = (value) => String(value).padStart(2, '0');
export const convert12HoursFormat = (time) => {
    const [timeWithoutPeriod, period] = time.split(' ');
    let [hours, minutes] = timeWithoutPeriod.split(':').map(Number);
    if (period === 'PM' && hours < 12) {
        hours += 12;
    }
    if (period === 'AM' && hours === 12) {
        hours = 0;
    }
    return `${formatTimeUnit(hours)}:${formatTimeUnit(minutes)}`;
};
export const convertTimeToMinutes = (time) => {
    const { hours, minutes } = parseTime(time);
    return hours * 60 + minutes;
};
export const emitNativeWebView = (data, fallback) => {
    if (window === null || window === void 0 ? void 0 : window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
    }
    else if (fallback) {
        fallback();
    }
};
export const buildSceneKey = (page) => {
    const { repeat_index, repeat_key, scene_key } = page;
    let key = scene_key;
    if (typeof repeat_index === 'number')
        key += `_index-${repeat_index}`;
    if (repeat_key)
        key += `_key-${repeat_key}`;
    return key;
};
export const formatFileSize = (sizeInBytes, baseSize = 1000) => {
    const kilobyte = baseSize;
    const megabyte = kilobyte * baseSize;
    let size;
    let unit;
    if (sizeInBytes >= megabyte) {
        size = sizeInBytes / megabyte;
        unit = 'MB';
    }
    else if (sizeInBytes >= kilobyte) {
        size = sizeInBytes / kilobyte;
        unit = 'KB';
    }
    else {
        size = sizeInBytes;
        unit = 'bytes';
    }
    return Math.floor(size) + unit;
};
export const getAnswerValue = (answer) => {
    if (answer && typeof answer === 'object' && 'parsed' in answer) {
        return answer.parsed;
    }
    return answer;
};
const getDecimalValue = (value) => {
    var _a;
    const parsed = String(value);
    const decimalNumber = Number(value);
    const minimumFractionDigits = ((_a = parsed.split('.')[1]) === null || _a === void 0 ? void 0 : _a.length) || 0;
    const raw_value = decimalNumber.toLocaleString(langCode, {
        maximumFractionDigits: 6,
        minimumFractionDigits,
    });
    return {
        parsed,
        raw_value,
    };
};
const getAnswerTypedValueType = (type) => {
    switch (type) {
        case ComputedType.BOOLEAN:
        case InputBlockTypedAnswerType.BOOLEAN:
            return ValuesType.BOOLEAN;
        case ComputedType.DECIMAL:
        case InputBlockTypedAnswerType.DECIMAL:
            return ValuesType.DECIMAL;
        case ComputedType.INTEGER:
        case InputBlockTypedAnswerType.INTEGER:
            return ValuesType.INTEGER;
        case ComputedType.STRING:
        case InputBlockTypedAnswerType.STRING:
            return ValuesType.STRING;
        case InputBlockTypedAnswerType.RANGE:
            return ValuesType.RANGE;
        case ComputedType.ZONED_DATETIME:
            return ValuesType.ZONED_DATETIME;
        default:
            return type;
    }
};
export const getComputedAnswer = (value, computed, page) => {
    const { type } = computed.resolver;
    const typed_value = {
        type: getAnswerTypedValueType(type),
        value,
    };
    if (value !== null && type === ComputedType.DECIMAL) {
        typed_value.value = getDecimalValue(value);
    }
    return {
        computed_value_id: computed.id,
        computed_value_key: computed.computed_value_key,
        id: uuid_v4(),
        page_key: page,
        typed_value,
    };
};
export const getInputBlockAnswer = (type, answer, block_key) => {
    const typed_value = {
        type: getAnswerTypedValueType(type),
        value: answer.value,
    };
    if (answer.value !== null &&
        (type === InputBlockTypedAnswerType.DECIMAL || type === ValuesType.DECIMAL)) {
        typed_value.value = getDecimalValue(answer.value);
    }
    return {
        answer_id: answer.id,
        answer_key: answer.key,
        answered: true,
        block_key,
        id: uuid_v4(),
        label: answer.label,
        page_key: {
            repeat_index: null,
            repeat_key: null,
            scene_key: '',
        },
        typed_value,
    };
};
export const getInitialFiles = (files = []) => {
    files = Array.isArray(files) ? files : [files];
    return files.map((f) => {
        return {
            options: {
                file: {
                    name: f.name,
                    size: f.size,
                    type: f.format,
                },
                type: 'limbo',
            },
            source: f.id,
        };
    });
};
/**
 * Filters an array of input block answers by key.
 * @param answersList - The array of answers to filter.
 * @param filtersList - The array of keys to filter by.
 * @param defaultValue - The default value to filter answeredList by.
 * @returns The Object containing answerFilters set and answers array filtered.
 */
export const filterBlockAnswers = (answersList, filtersList, defaultValue) => {
    const answerFilters = new Set(filtersList);
    const answersFiltered = (answerFilters.size > 0 ? answersList.filter((a) => answerFilters.has(a.key)) : answersList);
    const answeredList = answersList.filter((a) => isWithinDefaultValue(a.value, defaultValue));
    return {
        answerFilters,
        answeredList,
        answersFiltered,
    };
};
export const isWithinDefaultValue = (answer, val) => {
    switch (typeof val) {
        case 'boolean':
        case 'number':
        case 'string':
            return answer === val;
        case 'object':
            const isArr = Array.isArray(val);
            const isRange = val !== null && 'min' in val && 'max' in val;
            const isDecimal = val !== null && 'parsed' in val && 'raw_value' in val;
            if (isArr) {
                return val.some((val) => isWithinDefaultValue(answer, val));
            }
            else if (val && isRange) {
                return answer === val.min && answer === val.max;
            }
            else if (val && isDecimal) {
                return answer === val.parsed && answer === val.raw_value;
            }
        default:
            return false;
    }
};
// Gets value type from input block type e.g. boolean from input::boolean::boxes
export const getBlockTypeName = (type, matchPosition = 1) => {
    const match = type.split('::');
    return match ? match[matchPosition] : '';
};
// Check if an array of QueueItem has duplicates
export const hasDuplicates = (array) => {
    const seen = new Set();
    for (const item of array) {
        const key = buildSceneKey(item);
        if (seen.has(key))
            return true;
        seen.add(key);
    }
    return false; // No duplicates found
};
