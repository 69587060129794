export var SectionStatus;
(function (SectionStatus) {
    SectionStatus["ACTIVE"] = "active";
    SectionStatus["TODO"] = "todo";
    SectionStatus["COMPLETED"] = "completed";
})(SectionStatus || (SectionStatus = {}));
/**
 * Maps a list of queue items to their corresponding sections based on scene_key matching.
 *
 * @param {QueueItem[]} items - An array of items to be mapped to sections.
 * @param {Section[]} sections - An array of sections where each section defines the start of a new section.
 *
 * @returns {MappedSectionsWithIndices} - An object where each key is a section's scene_key, and the value is an object containing:
 *    - `scene_key`: The scene_key of the section.
 *    - `start_index`: The starting index of the items in this section.
 *    - `end_index`: The ending index of the items in this section.
 *    - `items`: An array of items that belong to this section.
 */
export function mapItemsToSectionsWithIndices(items, sections) {
    const mappedSections = {};
    let currentSection = null;
    let sectionStartIndex = null;
    items.forEach((item, index) => {
        sections.forEach((section) => {
            if (item.scene_key === section.page_key.scene_key) {
                if (currentSection && sectionStartIndex !== null) {
                    // Set the end index of the previous section
                    mappedSections[currentSection].end_index = index - 1;
                }
                currentSection = section.page_key.scene_key;
                sectionStartIndex = index;
                mappedSections[currentSection] = {
                    end_index: index,
                    items: [],
                    scene_key: section.page_key.scene_key,
                    start_index: index,
                };
            }
        });
        if (currentSection !== null) {
            mappedSections[currentSection].items.push(item);
        }
    });
    // Set the end index of the last section
    if (currentSection && sectionStartIndex !== null) {
        mappedSections[currentSection].end_index = items.length - 1;
    }
    return mappedSections;
}
/**
 * Determines the status of a section based on the current queue position.
 *
 * @param {MappedSectionsWithIndices} sectionMap - An object mapping sections to their corresponding queue items.
 * @param {Section} section - The section to determine the status of.
 * @param {string} mostForwardSectionKey - The scene_key of the most forward section.
 *
 * @returns {SectionStatus} - The status of the section.
 */
export const getSectionStatus = (sectionMap, section, mostForwardSectionKey) => {
    const mostForwardSection = sectionMap[mostForwardSectionKey];
    const activeSection = sectionMap[section.page_key.scene_key];
    if (activeSection.end_index < mostForwardSection.start_index) {
        return SectionStatus.COMPLETED;
    }
    if (activeSection.start_index >= mostForwardSection.start_index &&
        activeSection.end_index <= mostForwardSection.end_index) {
        return SectionStatus.ACTIVE;
    }
    return SectionStatus.TODO;
};
