import { setDefaultOptions } from 'date-fns';
import * as Locales from 'date-fns/locale';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE } from '@/types';
import { convertToDotNotation, langCode } from '@/utils';
import { resources } from './resources';
const defaultNS = 'app';
export default i18n.use(initReactI18next).init({
    defaultNS,
    fallbackLng: LANGUAGE.ENGLISH_GB,
    interpolation: {
        escapeValue: false,
    },
    lng: langCode,
    nonExplicitSupportedLngs: true,
    resources,
    returnObjects: true,
    supportedLngs: Object.keys(resources),
});
export const addTranslations = (languages) => {
    return new Promise((resolve) => {
        for (const { code, strings } of languages) {
            const localResource = i18n.getResourceBundle(code, defaultNS);
            const localStrings = convertToDotNotation(localResource);
            const allTranslations = Object.assign(Object.assign(Object.assign({}, localStrings), strings.markdown), strings.standard);
            i18n.removeResourceBundle(code, defaultNS);
            i18n.addResourceBundle(code, defaultNS, allTranslations, true, true);
        }
        i18n.reloadResources();
        resolve();
    });
};
export const getLocale = () => {
    var _a, _b;
    const [locale, region] = i18n.language.split('-');
    // @ts-ignore
    return (_b = (_a = Locales[locale + region]) !== null && _a !== void 0 ? _a : Locales[locale]) !== null && _b !== void 0 ? _b : Locales.enGB;
};
export const setLanguage = (language = i18n.language) => {
    i18n.changeLanguage(language);
    // set date-fns default locale
    const locale = getLocale();
    setDefaultOptions({ locale });
    // set language html attributes
    const htmlEl = document.querySelector('html');
    htmlEl.setAttribute('dir', i18n.dir());
    htmlEl.setAttribute('lang', language);
};
