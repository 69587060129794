var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useResolveValue } from '@/hooks';
import { CaptureService } from '@/services';
import { setCachedQueryAnswer, useQuestionnaire } from '@/store';
import { CachedQueryType, SourcedDataMetaType, } from '@/types';
export const useCachedQueries = (identifiers) => {
    const questionnaireCTX = useQuestionnaire();
    const [resolver] = useResolveValue();
    const handleQuery = (query) => {
        const { type } = query;
        const key = resolver.resolveString(query.key);
        switch (type) {
            case SourcedDataMetaType.HAS_KEY: {
                return {
                    key,
                    type,
                };
            }
            case SourcedDataMetaType.FULL_MATCH: {
                return {
                    key,
                    type,
                    value: resolver.resolveString(query.value),
                };
            }
            default: {
                throw new Error(`${type} is an unsupported Cached Query Type`);
            }
        }
    };
    const prepareData = (responses) => {
        const initialAccumulator = {
            custom: {
                many: {},
                single: {},
            },
            normal: [],
        };
        return responses.reduce((acc, { custom, normal }) => {
            var _a, _b;
            if (custom) {
                acc.custom.many = Object.assign(Object.assign({}, acc.custom.many), ((_a = custom.many) !== null && _a !== void 0 ? _a : {}));
                acc.custom.single = Object.assign(Object.assign({}, acc.custom.single), ((_b = custom.single) !== null && _b !== void 0 ? _b : {}));
            }
            if (normal) {
                acc.normal = [...acc.normal, ...normal];
            }
            return acc;
        }, initialAccumulator);
    };
    const handleCachedQueryRequests = (cachedQuery) => __awaiter(void 0, void 0, void 0, function* () {
        const { type } = cachedQuery;
        switch (type) {
            case CachedQueryType.CLIENT_59_OVERLAP: {
                const { group_by_key } = cachedQuery;
                const result = yield CaptureService.overlap({ group_by_key });
                return { normal: result };
            }
            case CachedQueryType.CUSTOM_MANY: {
                const result = yield CaptureService.cachedQueryMany(Object.assign(Object.assign({}, identifiers), { query_key: cachedQuery.request_key }));
                return {
                    custom: {
                        many: { [cachedQuery.result_cache_key]: result },
                    },
                };
            }
            case CachedQueryType.CUSTOM_SINGLE: {
                const result = yield CaptureService.cachedQuerySingle(Object.assign(Object.assign({}, identifiers), { query_key: cachedQuery.request_key }));
                return {
                    custom: {
                        single: { [cachedQuery.result_cache_key]: result },
                    },
                };
            }
            case CachedQueryType.LATEST: {
                const { answer_keys: answers, query } = cachedQuery;
                const result = yield CaptureService.latestSourcedData({
                    answers,
                    query: handleQuery(query),
                });
                return { normal: result };
            }
            default: {
                throw new Error(`${type} is an unsupported Cached Query`);
            }
        }
    });
    const handleCachedQueries = (cachedQueries = []) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const promises = cachedQueries.map(handleCachedQueryRequests);
            const resultsArray = yield Promise.all(promises);
            const results = prepareData(resultsArray);
            questionnaireCTX.dispatch(setCachedQueryAnswer(results));
        }
        catch (error) {
            throw new Error(error);
        }
    });
    return {
        handleCachedQueries,
    };
};
