import { jsx as _jsx } from "react/jsx-runtime";
import { useRadioGroup } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { BlockErrors, InputGroupBase, RadioItem } from '@/components';
import { useQuestionnaire } from '@/store';
import { getAnswerValue, getInputBlockAnswer } from '@/utils';
export const RadioDynamicCachedQuery = ({ block: { answer_value_type, id, key }, defaultValue, errors = [], onChange, }) => {
    const { mapCachedQueryAnswersByBlock } = useQuestionnaire();
    const options = useMemo(() => mapCachedQueryAnswersByBlock.get(key) || [], [key]);
    useEffect(() => {
        const value = getAnswerValue(defaultValue);
        const option = options.find((option) => option.value === value);
        if (option)
            setValue(option.key);
    }, []);
    const { getRadioProps, getRootProps, setValue } = useRadioGroup({
        defaultValue,
    });
    const handleChange = (answer) => {
        const newAnswer = getInputBlockAnswer(answer_value_type, answer, key);
        // @ts-ignore
        onChange(newAnswer, { order: answer.order });
        if (answer.value === null)
            setValue(null);
    };
    return (_jsx(BlockErrors, Object.assign({ errors: errors }, { children: _jsx(InputGroupBase, Object.assign({ "data-test": id }, getRootProps(), { children: options.map((item) => {
                const value = item.key;
                return (_jsx(RadioItem, Object.assign({ answer: item, answerChange: handleChange }, getRadioProps({ value })), item.key));
            }) })) })));
};
