var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, Text, VStack, useRadioGroup } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, CentredWrapper, Icon, InputRadioBase, Loading, ScreenIntroText, } from '@/components';
import { AuthService } from '@/services';
import { useAuth } from '@/store';
import { IdentityProviderType, ROUTE, UrlParamsKey } from '@/types';
export const Identity = () => {
    const { t } = useTranslation();
    const [urlParams] = useSearchParams();
    const navigate = useNavigate();
    const { handleAuth, state: { identityProviders }, } = useAuth();
    const { getRadioProps, getRootProps, value } = useRadioGroup();
    const callbackParams = {
        code: urlParams.get(UrlParamsKey.CODE),
        state: urlParams.get(UrlParamsKey.STATE),
    };
    useEffect(() => {
        handleProviderInit();
    }, []);
    const handleProviderInit = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (callbackParams.code) {
                yield handleProviderCallback();
            }
            else {
                yield handleProviderCheck();
            }
        }
        catch (e) {
            navigate(ROUTE.CONTACT_SITE);
        }
    });
    const handleProviderCheck = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const samlProvider = identityProviders === null || identityProviders === void 0 ? void 0 : identityProviders.participant.find((p) => p.type === IdentityProviderType.SAML);
            window.location.href = samlProvider.url;
        }
        catch (e) {
            throw new Error('SAML provider check error');
        }
    });
    const handleProviderCallback = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield AuthService.getToken(callbackParams);
            yield handleAuth();
        }
        catch (e) {
            throw new Error('SAML provider callback error');
        }
    });
    // NOTE: The implementation of multiple identity providers is completed but not yet supported.
    // Currently, we return null as provider selection is unnecessary; only SAML is supported.
    // In the future, multiple providers will be available, allowing users to choose their preferred option.
    return _jsx(Loading, {});
    const handleProviderAuth = () => {
        const provider = identityProviders === null || identityProviders === void 0 ? void 0 : identityProviders.participant.find((p) => p.name === value);
        if (provider) {
            switch (provider.type) {
                case IdentityProviderType.LOCAL:
                    navigate(ROUTE.HOME);
                    break;
                case IdentityProviderType.SAML:
                    window.location.href = provider.url;
                    break;
            }
        }
    };
    return (_jsxs(CentredWrapper, { children: [_jsx(ScreenIntroText, { subtitle: t('page:identity.subtitle'), title: t('page:identity.title') }), _jsx(VStack, Object.assign({}, getRootProps(), { w: "100%" }, { children: identityProviders === null || identityProviders === void 0 ? void 0 : identityProviders.participant.map(({ display_name, name, type }) => {
                    const radio = getRadioProps({ value: name });
                    const isSelected = value === name;
                    return (_createElement(InputRadioBase, Object.assign({}, radio, { baseStyle: {
                            px: '4',
                            gridGap: '2',
                            _checked: {
                                borderColor: 'green.60',
                            },
                            _hover: {
                                borderColor: 'green.60',
                            },
                        }, key: name, testId: name }),
                        _jsx(Icon, { name: "padlock", size: "8", type: isSelected ? 'solid' : 'line' }),
                        _jsxs(Flex, Object.assign({ alignItems: "flex-start", direction: "column", flex: "1" }, { children: [_jsx(Text, { children: display_name }), _jsx(Text, Object.assign({ fontSize: "xs", opacity: "0.7", textTransform: "uppercase" }, { children: type }))] })),
                        isSelected && _jsx(Icon, { color: "green.60", name: "check" })));
                }) })), _jsx(Button, Object.assign({ isDisabled: !value, onClick: handleProviderAuth }, { children: t('page:identity.authenticateButton') }))] }));
};
