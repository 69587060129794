import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Center, Divider, HStack, Image, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ProfileWhite from '@/assets/img/icons/profile-white.svg';
import logoWhiteNoText from '@/assets/img/logo-white-no-text.svg';
import { BreadCrumbs } from '@/components';
import { useAuth } from '@/store';
import { DataTest, ROUTE } from '@/types';
const toastManager = document.querySelector('#chakra-toast-manager-top');
export const Header = () => {
    var _a, _b;
    const { t } = useTranslation();
    const [height, setHeight] = useState(0);
    const { isLoggedIn, state: { currentRoute }, } = useAuth();
    const resizeObserver = new ResizeObserver((entries) => setHeight(entries[0].target.clientHeight));
    resizeObserver.observe(toastManager);
    const title = isLoggedIn ? t('app:study.name') : t('common:header.portalName');
    return (_jsxs(Box, Object.assign({ as: "header", mt: `${height}px`, transition: "all 0.25s ease" }, { children: [_jsx(Center, Object.assign({ bg: "darkBrandBlue.100", color: "white", h: "80px", pos: "relative" }, { children: _jsxs(HStack, Object.assign({ alignItems: "center", justifyContent: "space-between", maxW: "1200px", px: "4", w: "100%" }, { children: [_jsxs(HStack, Object.assign({ alignItems: "center", spacing: "6" }, { children: [_jsx(Image, { alt: "uMotif", src: logoWhiteNoText }), _jsx(Divider, { borderColor: "white", h: "50px", opacity: ".2", orientation: "vertical" }), _jsx(Text, Object.assign({ "data-test": DataTest.HEADER_PORTAL_NAME, fontFamily: "fonts.heading", fontSize: "md" }, { children: title }))] })), isLoggedIn && (_jsx(Link, Object.assign({ to: ROUTE.PROFILE }, { children: _jsxs(HStack, Object.assign({ alignItems: "center", spacing: "3" }, { children: [_jsx(Text, Object.assign({ fontFamily: "fonts.heading", fontSize: "xs" }, { children: t('app:components.navigation.profile') })), _jsx(Image, { alt: t('app:components.navigation.profile'), src: ProfileWhite })] })) })))] })) })), ((_b = (_a = currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.settings) === null || _a === void 0 ? void 0 : _a.breadcrumb) === null || _b === void 0 ? void 0 : _b.display) && (_jsx(BreadCrumbs, { breadcrumbs: [
                    {
                        text: t('app:components.navigation.dashboard'),
                        url: ROUTE.DASHBOARD,
                    },
                    {
                        text: currentRoute.title,
                        url: currentRoute.path,
                    },
                ] }))] })));
};
