import { jsx as _jsx } from "react/jsx-runtime";
import { useCheckboxGroup } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { BlockErrors, CheckboxItem, InputGroupBase } from '@/components';
import { useResolveValue } from '@/hooks';
import { ValuesType } from '@/types';
import { filterBlockAnswers, getInputBlockAnswer } from '@/utils';
export const CheckboxGroup = (props) => {
    const { block: { answer_filters = [], answers = [], id, images = [], key, max }, defaultValue = [], errors = [], onChange, } = props;
    const { getCheckboxProps, setValue, value: checkedList, } = useCheckboxGroup({
        defaultValue,
    });
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);
    const [resolver] = useResolveValue();
    const resolved = useMemo(() => {
        const answerKeys = resolver.resolveAnswerFilter(answer_filters);
        const filters = filterBlockAnswers(answers, answerKeys, defaultValue);
        const answersList = filters.answersFiltered.map((a, order) => {
            return Object.assign(Object.assign({}, a), { label: resolver.resolveString(a.label), order });
        });
        return {
            answersList,
        };
    }, []);
    const isDisabled = (val) => !checkedList.includes(val) && checkedList.length >= max;
    const handleChange = (answer) => {
        const newAnswer = getInputBlockAnswer(ValuesType.STRING, answer, key);
        onChange(newAnswer, {
            multipleAnswer: true,
            order: answer.order,
        });
    };
    return (_jsx(BlockErrors, Object.assign({ errors: errors }, { children: _jsx(InputGroupBase, Object.assign({ "data-test": id }, { children: resolved.answersList.map((item) => {
                const { id, value } = item;
                return (_jsx(CheckboxItem, Object.assign({ answer: item, answerChange: handleChange, images: images, isDisabled: isDisabled(value) }, getCheckboxProps({ value })), id));
            }) })) })));
};
