import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isClinro } from '@/config';
import { useQuestionnaire } from '@/store';
import { UrlParamsKey } from '@/types';
import { useToast } from './useToast';
var MessageType;
(function (MessageType) {
    MessageType["CHECK"] = "check";
    MessageType["FOUND"] = "found";
})(MessageType || (MessageType = {}));
export const useBroadcastClinro = () => {
    const [searchParams] = useSearchParams();
    const { showToast } = useToast();
    const { state: { questionnaire: { id: questionnaireId }, }, } = useQuestionnaire();
    const participantId = searchParams.get(UrlParamsKey.SUBMITTED_FOR);
    const messageId = useMemo(() => ({ participantId, questionnaireId }), []);
    const handleShowWarning = () => {
        showToast({
            message: 'This questionnaire is open in another tab',
            permanent: true,
            status: 'warning',
        });
    };
    useEffect(() => {
        // Only check for duplicated questionnaires in clinro mode
        if (!isClinro && !messageId.participantId && !messageId.questionnaireId)
            return;
        const channel = new BroadcastChannel('questionnaire_channel');
        channel.postMessage({
            ids: messageId,
            type: MessageType.CHECK,
        });
        const handleMessage = (event) => {
            const { ids, type } = event.data;
            const isMatchIds = ids.participantId === messageId.participantId &&
                ids.questionnaireId === messageId.questionnaireId;
            if (isMatchIds) {
                // Check if questionnaire is duplicated, show warning and respond with a message
                if (type === MessageType.CHECK) {
                    handleShowWarning();
                    channel.postMessage({
                        ids: messageId,
                        type: MessageType.FOUND,
                    });
                }
                // Check if is the duplicated found match and show warning
                if (type === MessageType.FOUND) {
                    handleShowWarning();
                }
            }
        };
        channel.addEventListener('message', handleMessage);
        return () => {
            channel.removeEventListener('message', handleMessage);
            channel.close();
        };
    }, [messageId]);
};
